import { HashRouter, Route, Routes } from "react-router-dom";
import { Menu } from "./components/Menu/Menu";
import { About } from "./pages/About";
import { Home } from "./pages/Home";
import './App.css';
import "./i18n";
import { useTranslation } from "react-i18next";
import bck from './img/background.webp';
import bck_mobile from './img/mobile.webp';
import { Day } from "./pages/Day";
import { Registry } from "./pages/Registry";
import { Hotel } from "./pages/Hotel";
import { Logistica } from "./pages/Logistica";
import { useEffect } from "react";
import { Story } from "./pages/Story";
import { ToDo } from "./pages/ToDo";
import { Button } from "react-bootstrap";

function App() {
  const { i18n, t } = useTranslation();
  var fechaCuentaRegresiva = "07/05/2025 18:30:00";
  // Set the date we're counting down to
  var countDownDate = new Date(fechaCuentaRegresiva).getTime();

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const lang = params.get('lang');
    if (lang === 'es' || lang === 'us' || lang === 'it') {
      i18n.changeLanguage(lang);
    }
  },[i18n])

  return (
    <div className="App">
      <div className="background">
        <picture className="picture">
          <img loading="eager" alt="" src={bck} className="bck-img"/>
          <img loading="eager" alt="" src={bck_mobile} className="bck-img-mobile"/>
        </picture>
      </div>
      <header className="header">
        <h1 className="header-title">Alberto & maria</h1>
        {i18n.language === 'es' && (
          <div className="header-when">
            <Button style={{backgroundColor: "white", color: "#314C67", border: "0px"}} target="_blank" href={"https://forms.gle/Uft4edmhDAocRFvQ7"}>{t("registro")}</Button>
          </div>
        )}
        <div className="header-when" style={{marginTop: "30px"}}>{t('date')} • Granada, {t('country')}</div>
        <div className="countdown">{days===0 ? (<>{t('day_on')}</>) : (<>{days} {t('countdown')}</>)}</div> 
      </header>
      <div className="content">
      <HashRouter>
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/story" element={<Story />} />
          <Route path="/day" element={<Day />} />
          <Route path="/hotel" element={<Hotel/>} />   
          <Route path="/planning" element={<Logistica />} />     
          <Route path="/registry" element={<Registry />} />
          <Route path="/what" element={<ToDo />} />                    
        </Routes>
      </HashRouter>
      </div>
      <footer>
        <div className="footer">
          <div className="footer-letter">
            A&M
          </div>
          <div className="footer-date">
            {t("fotter_date")}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
